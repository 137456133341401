// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-posts-js": () => import("/Users/philip/projects/philipschweiger.com.source/src/templates/posts.js" /* webpackChunkName: "component---src-templates-posts-js" */),
  "component---src-pages-404-js": () => import("/Users/philip/projects/philipschweiger.com.source/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("/Users/philip/projects/philipschweiger.com.source/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/Users/philip/projects/philipschweiger.com.source/.cache/data.json")

